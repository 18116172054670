


import { mapGetters } from 'vuex';
import Vue from 'vue';

import { TPersonalStat } from '@/_modules/statistics/types/personal-stat.type';
import {
  TUserStatMeetings,
  TUserStatMessages,
  TUserStatNotes,
  TUserStatPromoPages
} from '@/_modules/statistics/types/promo-page-stat.type';
import FileHelper from '@/_helpers/file.helper';

type TPersonalStatData = {

}

interface IPersonalStatMethods {
  percents: (val: number, total: number) => void;
  order: (val: number, val2: number) => number;
  meetingsConfirmedSum: (meetings: TUserStatMeetings) => number;
  exportFileContact: (exportFileFormat: string) => void;
  exportFilePromopage: (exportFileFormat: string) => void;
  exportFileMessages: (exportFileFormat: string) => void;
  exportFileMeetings: (exportFileFormat: string) => void;
  exportFileNotes: (exportFileFormat: string) => void;
  isDisabledExportContact: (contacts: TUserStatPromoPages) => boolean;
  isDisabledExportMeetings: (contacts: TUserStatMeetings) => boolean;
  isDisabledExportMessages: (contacts: TUserStatMessages) => boolean;
  isDisabledExportNotes: (contacts: TUserStatNotes) => boolean;
  isDisabledExportPromoPages: (contacts: TUserStatPromoPages) => boolean;
  downloadFile: (file: File, filename: string) => void;
}

type TPersonalStatComputed = {
  personalStat: TPersonalStat;
  isLoadingPersonal: boolean;
  eventId: number;
}

const StatisticsPersonal = Vue.extend<TPersonalStatData, IPersonalStatMethods, TPersonalStatComputed>({
  name: 'statistics-personal',
  computed: {
    ...mapGetters('statisticStore', {
      personalStat: 'personal',
      isLoadingPersonal: 'isLoadingPersonal',
    }),
    eventId(): number {
      return Number(this.$route.params.eventId);
    }
  },
  data(): TPersonalStatData {
    return {
    };
  },
  watch: {
    eventId: {
      immediate: true,
      handler(): void {
        this.$store.dispatch('statisticStore/getPersonal', { eventId: this.eventId });
      }
    },
  },
  methods: {
    percents(val: number, total: number): number {

      if (total !== 0 && val < total) {
        return (val / total) * 100;
      } else {
        return 100;
      }

    },
    order(val: number, val2: number): number {
      return (val > val2) ? 0 : 1;
    },
    meetingsConfirmedSum(meetings: TUserStatMeetings): number {
      if (!meetings) { return 0; }
      return meetings.by_me.confirmed + meetings.to_me.confirmed;
    },
    async exportFileContact(exportFileFormat: string): Promise<void> {
      const file = await this.$store.dispatch('statisticStore/exportContacts', {
        eventId: this.eventId,
        format: exportFileFormat
      });
      this.downloadFile(file, 'EventsWallet_contacts_statistics.' + exportFileFormat);
    },
    async exportFilePromopage(exportFileFormat: string): Promise<void> {
      const file = await this.$store.dispatch('statisticStore/exportPromoPages', {
        eventId: this.eventId,
        format: exportFileFormat
      });
      this.downloadFile(file, 'EventsWallet_promopages_statistics.' + exportFileFormat);
    },
    async exportFileMessages(exportFileFormat: string): Promise<void> {
      const file = await this.$store.dispatch('statisticStore/exportMessages', {
        eventId: this.eventId,
        format: exportFileFormat
      });
      this.downloadFile(file, 'EventsWallet_messages_statistics.' + exportFileFormat);
    },
    async exportFileMeetings(exportFileFormat: string): Promise<void> {
      const file = await this.$store.dispatch('statisticStore/exportMeetings', {
        eventId: this.eventId,
        format: exportFileFormat
      });
      this.downloadFile(file, 'EventsWallet_meetings_statistics.' + exportFileFormat);
    },
    async exportFileNotes(exportFileFormat: string): Promise<void> {
      const file = await this.$store.dispatch('statisticStore/exportNotes', {
        eventId: this.eventId,
        format: exportFileFormat
      });
      this.downloadFile(file, 'EventsWallet_notes_statistics.' + exportFileFormat);
    },
    downloadFile(file: File, filename: string): void {
      FileHelper.downloadFile(file, filename);
    },
    isDisabledExportContact(contacts: TUserStatPromoPages): boolean {
      if (!contacts) { return true; }
      return contacts.by_me.favorite === 0;
    },
    isDisabledExportMeetings(meetings: TUserStatMeetings): boolean {
      if (!meetings) { return true; }
      return meetings.total_confirmed === 0;
    },
    isDisabledExportMessages(messages: TUserStatMessages): boolean {
      if (!messages) { return true; }
      return messages.by_me.total === 0 && messages.to_me.total === 0;
    },
    isDisabledExportNotes(notes: TUserStatNotes): boolean {
      if (!notes) { return true; }
      return notes.by_me.favorite === 0;
    },
    isDisabledExportPromoPages(promoPages: TUserStatPromoPages): boolean {
      if (!promoPages) { return true; }
      return promoPages.by_me.favorite === 0;
    }
  },
});
export default StatisticsPersonal;
